
import { defineComponent } from "vue";
import Button from "@/components/common-elements/Button.vue";

export default defineComponent({
  name: "Flashmob",
  components: {
    Button,
  },
});
